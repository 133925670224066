import React from "react";
import LayoutForm from "../components/layoutForm";
import { graphql, Link, navigate } from "gatsby";
import queryString from "query-string";
import { isBrowser, setPN, getSN, setSN } from "../services/auth";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/EHSSmall.png";
import { faAngleDoubleRight, faRing } from "@fortawesome/free-solid-svg-icons";
import imagePortal from "../images/background1.jpg";

const LargeNavButton = styled(Link)`
  background-color: #e1f5fd;
  color: #0a54a5;
  z-index: 1;
  width: 50%;
  margin: auto;
  padding: 40px;
  border-radius: 20px;
  transition: background-color 250ms ease-out, color 250ms ease-out;
  margin-bottom: 0;
  margin-top: 70px;
  text-decoration: none;
  text-align: center;
  line-height: 39px;
  font-size: 44px;
  box-shadow: rgb(0 0 0 / 69%) 0px 1px 29px;
  &:hover {
    background-color: #0a54a5;
    color: white;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
    padding: 40px 10px;
  }
`;

const LogoImage = styled.img`
  width: 99px;
  margin-bottom: 5px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 80px;
  margin-bottom: 30px;
`;

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.getID();
  }

  getID() {
    if (isBrowser()) {
      var query = queryString.parse(window.location.search);
      if (query !== undefined && query["sn"] === undefined && getSN() !== "") {
      }
      if (query !== undefined && query["sn"] !== undefined) {
        setSN(query["sn"]);
        if (query["sn"] !== undefined) {
          setPN(query["pn"]);
          navigate("library/literature");
        }
      }
    }
    return 1;
  }
  render() {
    return (
      <LayoutForm
        image={imagePortal}
        headerTitle={
          <>
            PerkinElmer&nbsp;<span style={{ color: "#707070" }}>Portal</span>
          </>
        }
        noBackground={true}
      >
        <LargeNavButton to="/library/literature">
          <StyledFontAwesomeIcon icon={faRing}></StyledFontAwesomeIcon>
          <br />
          GC Column Portal&nbsp;
          <FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
        </LargeNavButton>
        <LargeNavButton to="/forms/incident-report/">
          <LogoImage src={logo}></LogoImage>
          <br />
          EHS Incident Portal&nbsp;
          <FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
        </LargeNavButton>
      </LayoutForm>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
